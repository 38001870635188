import { getFromLocalStorage, ready, setToLocalStorage } from '#js/components/utils'
import { initPusher } from '#js/integrations/pusher'

export function initializePusher () {
  const disabledForBrowser = getFromLocalStorage('push-notifications-disabled', false)

  if (globalThis.hasPushNotificationsEnabled && !disabledForBrowser && Notification.permission === 'granted') {
    initPusher()
  }
}

export function requestBrowserPermissions () {
  window.dataLayer.push({
    event: 'request_push_notifications'
  })
  Notification.requestPermission().then(function (permission) {
    if (permission === 'granted') {
      window.dataLayer.push({
        event: 'grant_push_notifications'
      })
      window.location.reload()
    } else {
      window.dataLayer.push({
        event: 'deny_push_notifications'
      })
    }
  })
}

// handle permission requests including modal with help text
export function initializePushNotificationModal () {
  const disabledForBrowser = getFromLocalStorage('push-notifications-disabled', false)

  if (globalThis.hasPushNotificationsEnabled && !disabledForBrowser && Notification.permission !== 'granted') {
    const pushNotificationModal = document.getElementById('push-notifications-dialog')
    pushNotificationModal.showModal()

    const modalEnable = document.getElementById('push-notifications-enable')
    const modalDisable = document.getElementById('push-notifications-disable')
    const modalDismiss = document.getElementById('push-notifications-dismiss')

    modalEnable.addEventListener('click', function (event) {
      event.preventDefault()
      requestBrowserPermissions()
    })

    modalDisable.addEventListener('click', function (event) {
      event.preventDefault()
      pushNotificationModal.close()
      setToLocalStorage('push-notifications-disabled', true)
    })

    modalDismiss.addEventListener('click', function (event) {
      event.preventDefault()
      pushNotificationModal.close()
    })
  }
}

if ('Notification' in window) {
  ready(initializePusher)
  ready(initializePushNotificationModal)
}

export default {
  initializePusher,
  initializePushNotificationsModal: initializePushNotificationModal
}
